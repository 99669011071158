import { combineReducers, configureStore } from '@reduxjs/toolkit';

// import logger from 'redux-logger';
import authSlice from './auth/authSlice';
import candidateSlice from './candidate/candidateSlice';
import profileSlice from './profile/profileSlice';
import { isEnvProduction } from '../utils/isenvProduction';
import organizationSlice from './organization/organizationSlice';
import rolesSlice from './roles/rolesSlice';
import dataSlice from './data/dataSlice';
import enrollmentSlice from './enrollment/enrollmentSlice';
import clientsSlice from './clients/clientsSlice';
import clientProfileSlice from './clientProfile/clientProfileSlice';
import screenerSlice from './screeener/screenerSlice';
import myTodosSlice from './myTodos/myTodosSlice';
import healthTrackerSlice from './healthTracker/healthTrackerSlice';
import roadmapsSlice from './roadmaps/roadmapsSlice';
const combinedReducer = combineReducers({
  auth: authSlice,
  profile: profileSlice,
  candidate: candidateSlice,
  organization: organizationSlice,
  roles: rolesSlice,
  data: dataSlice,
  enrollment: enrollmentSlice,
  clients: clientsSlice,
  clientProfile: clientProfileSlice,
  screener: screenerSlice,
  myTodos: myTodosSlice,
  healthTracker: healthTrackerSlice,
  roadmaps: roadmapsSlice
});

const store = configureStore({
  reducer: combinedReducer,
  devTools: !isEnvProduction(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      immutableCheck: false,
      serializableCheck: false
    })
  //.concat(logger)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
