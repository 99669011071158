import * as React from 'react';

import style from './errorContainer.module.scss';

interface ErrorContainerProps {
  errors: any;
  visible: boolean | string | undefined;
  name: string;
  staticTextError?: string;
  autoHeight?: boolean;
}

export const ErrorContainer: React.FunctionComponent<ErrorContainerProps> = ({
  errors,
  staticTextError = null,
  visible,
  name,
  autoHeight
}) => {
  return (
    <div className={autoHeight ? '' : style.container}>
      {visible && !staticTextError ? <div className={style.fieldError}>{errors[name]}</div> : null}
      {visible && staticTextError ? (
        <div className={style.fieldError}>{staticTextError}</div>
      ) : null}
    </div>
  );
};

export default ErrorContainer;
