import { ClickAwayListener } from '@mui/material';
import { Button, Stack } from 'components';
import dayjs from 'dayjs';
import { Formik, FormikHelpers } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { deleteNewTodoDraft, postCreateTodo } from 'store/myTodos/myTodosActions';
import {
  clearNewTodoDraft,
  setStatus,
  Todo as TodoType,
  toggleClientViewSection,
  toggleDayViewSection
} from 'store/myTodos/myTodosSlice';
import { getTodos as getRoadmapsTodos } from 'store/roadmaps/roadmapsActions';
import { notifyUserError } from 'utils/notifications';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Checkbox from '../components/checkbox/checkbox';
import { getHasDifferentEndDate, mapDates } from '../utils/date';
import { validationSchema } from '../utils/validation';
import style from './addTodoForm.module.scss';
import AddTodoFormActions from './addTodoFormActions';
import TitleInput from '../components/titleInput/titleInput';

export const colors = {
  teal: '#03A59D',
  blue: '#15A0E0',
  marine: '#4062D1',
  purple: '#9252D1',
  pink: '#C959C5',
  yellow: '#B1A110'
};

const initialValues = {
  id: null,
  title: '',
  date: null,
  hasDifferentEndDate: false,
  endDate: null,
  startTime: null,
  endTime: null,
  context: 'myTodos',
  goalId: null,
  location: '',
  note: '',
  color: colors.teal,
  client: null
};

const getInitialValues = (todo: TodoType['draft'] | null) => {
  if (todo && todo.creationContext === 'MY_TODOS') {
    return {
      ...todo,
      date:
        todo.startDateTime || todo.date
          ? dayjs(todo.startDateTime ?? todo.date).format('YYYY-MM-DD')
          : null,
      endDate: todo.endDateTime ? dayjs(todo.endDateTime).format('YYYY-MM-DD') : null,
      startTime: todo.startDateTime ? dayjs(todo.startDateTime).format('HH:mm') : null,
      endTime: todo.endDateTime ? dayjs(todo.endDateTime).format('HH:mm') : null,
      hasDifferentEndDate: getHasDifferentEndDate(todo.startDateTime, todo.endDateTime)
    };
  }

  return initialValues;
};

export type AddTodoFormValues = ReturnType<typeof getInitialValues>;

const scrollToElementById = (id: string) => {
  const element = document.getElementById(id);
  element?.scrollIntoView({
    block: 'start'
  });
};

const AddTodoForm = () => {
  const clientId = useAppSelector((state) => state.clients.client.data?.id);
  const view = useAppSelector((state) => state.myTodos.view);
  const status = useAppSelector((state) => state.myTodos.filters.status);
  const dispatch = useAppDispatch();
  const newTodoDraft = useAppSelector((state) => state.myTodos.newTodoDraft);
  const [expandForm, setExpandForm] = useState(newTodoDraft != null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const hasOpenedPopper = useAppSelector((state) => state.myTodos.hasOpenedPopper);
  const { enableMyTodosPostRelease } = useFlags();

  const onCancel = async () => {
    setExpandForm(false);
    dispatch(clearNewTodoDraft());
    if (newTodoDraft?.id) {
      await dispatch(deleteNewTodoDraft({ draftId: newTodoDraft?.id }));
    }
  };

  const focusInput = () => {
    inputRef.current?.focus();
  };

  const openCorrectSection = (sectionName: string) => {
    if (view === 'dayView') {
      dispatch(toggleDayViewSection({ section: sectionName, expanded: true }));
    } else if (view === 'clientView') {
      dispatch(toggleClientViewSection({ section: sectionName, expanded: true }));
    }
  };

  const scrollToCorrectSection = ({
    startDateTime,
    date,
    values
  }: {
    startDateTime: string | null;
    date: string | null;
    values: ReturnType<typeof getInitialValues>;
  }) => {
    if (view === 'dayView') {
      const isNoDateTodo = values.date == null && values.startTime == null;
      if (isNoDateTodo) {
        openCorrectSection('No date');
        scrollToElementById('No date');
        return;
      }
      const isOverdueTodo = dayjs(startDateTime || date).isBefore(dayjs(), 'minutes');
      if (isOverdueTodo) {
        openCorrectSection('Overdue');
        scrollToElementById('Overdue');
        return;
      }
      if (startDateTime || date) {
        const sectionName = dayjs(startDateTime || date).format('MMM D, dddd');
        openCorrectSection(sectionName);
        scrollToElementById(sectionName);
        return;
      }
    }
    if (view === 'clientView') {
      if (values.client) {
        openCorrectSection(values.client.name);
        scrollToElementById(values.client.name);
      } else {
        openCorrectSection('No client');
        scrollToElementById('No client');
      }
    }
  };

  const onSubmit = async (
    values: ReturnType<typeof getInitialValues>,
    formikHelpers: FormikHelpers<ReturnType<typeof getInitialValues>>
  ) => {
    const { date, startDateTime, endDateTime } = mapDates({
      date: values.date,
      endDate: values.endDate,
      startTime: values.startTime,
      endTime: values.endTime,
      hasDifferentEndDate: values.hasDifferentEndDate
    });

    if (enableMyTodosPostRelease && (values.title === '' || values.title == null)) {
      notifyUserError('Description is required');
      return;
    }

    const response = await dispatch(
      postCreateTodo({
        title: values.title,
        clientId: values.client?.id,
        goalId: values.goalId,
        note: values.note,
        location: values.location,
        color: values.color,
        startDateTime,
        endDateTime,
        date,
        status: 'ACTIVE',
        draftId: newTodoDraft?.id
      })
    );

    if (response.meta.requestStatus === 'fulfilled') {
      if (!status.includes('ACTIVE') && status.length > 0) {
        dispatch(setStatus([...status, 'ACTIVE']));
      }
      formikHelpers.resetForm({ values: initialValues });
      focusInput();
      scrollToCorrectSection({ startDateTime, date, values });
      if (clientId) {
        dispatch(getRoadmapsTodos({ clientId }));
      }
    }
  };

  return (
    <Formik
      initialValues={getInitialValues(newTodoDraft)}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, dirty, values, isValid, isSubmitting, resetForm, setSubmitting }) => {
        const isDisabled = isSubmitting || !isValid;
        return (
          <form onSubmit={handleSubmit}>
            <ClickAwayListener
              onClickAway={() => {
                if (dirty || newTodoDraft || hasOpenedPopper) {
                  return;
                }

                setExpandForm(false);
              }}
            >
              <div data-show-color={expandForm} data-color={values.color} className={style.wrapper}>
                <Stack display="flex" flexDirection="column">
                  <Stack display="flex" alignItems="start" flexDirection="row" width="100%">
                    <AnimatePresence initial={false}>
                      {expandForm && (
                        <motion.div
                          initial={{ opacity: 0, width: 0 }}
                          animate={{
                            opacity: 1,
                            width: 'auto',
                            transition: { opacity: { delay: 0.3 } }
                          }}
                          exit={{
                            opacity: 0,
                            width: 0,
                            transition: { width: { delay: 0.3 } }
                          }}
                        >
                          <Checkbox value={false} disabled readOnly />
                        </motion.div>
                      )}
                    </AnimatePresence>
                    <TitleInput
                      expandable
                      ref={inputRef}
                      onFocus={() => setExpandForm(true)}
                      onClick={() => setExpandForm(true)}
                      wrapperClassName={style.titleInputWrapper}
                    />
                  </Stack>
                  <AnimatePresence initial={false}>
                    {expandForm && (
                      <motion.div
                        style={{ position: 'relative' }}
                        initial={{ height: 0 }}
                        animate={{ height: 'auto' }}
                        exit={{ height: 0, transition: { delay: 0.3 } }}
                      >
                        <AddTodoFormActions />
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <AnimatePresence initial={false}>
                    {(dirty || newTodoDraft != null) && (
                      <motion.div
                        layout
                        initial={{ opacity: 0, height: 0 }}
                        exit={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                      >
                        <Stack display="flex" flexDirection="row" gap="8px" marginTop="10px">
                          <Button
                            handleOnClick={async () => {
                              await setSubmitting(true);
                              resetForm({ values: initialValues });
                              await onCancel();
                              setSubmitting(false);
                            }}
                            text="Cancel"
                            theme="transparent"
                            type="button"
                            size="extraSmall"
                          />
                          <Button
                            size="extraSmall"
                            text="Save"
                            theme="primary"
                            disabled={isDisabled}
                          />
                        </Stack>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Stack>
              </div>
            </ClickAwayListener>
          </form>
        );
      }}
    </Formik>
  );
};

export default AddTodoForm;
