import {
  Pagination,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Table as TableMaterial,
  TableRow
} from '@mui/material';
import clsx from 'clsx';
import { Tooltip } from 'components/tooltip';
import { forwardRef, useState } from 'react';

import { LoadingSpinner } from '../loadingSpinner';
import style from './table.module.scss';

export interface HeadCell {
  id: string;
  label: string | JSX.Element;
  sort?: boolean;
  isMustHide?: boolean;
  disabled?: boolean;
  tooltip?: {
    text: string;
    placement?: string;
    type: 'dark' | 'light';
  };
  className?: string;
}

export enum SortValues {
  ASC = 'asc',
  DESC = 'desc'
}

interface Props {
  isLoading: boolean;
  columns: any;
  headCells: HeadCell[];
  totalNumber: number;
  page: number;
  size: number;
  getTableData?: any;
  multisort?: boolean;
  testIdAttr?: string;
  tableWrapClassName?: string;
  handleOnSort?: (sortConfig: any) => void;
}

const Table = forwardRef<HTMLDivElement, Props>(
  (
    {
      columns,
      isLoading,
      headCells,
      totalNumber,
      multisort,
      page,
      size,
      getTableData,
      testIdAttr,
      tableWrapClassName,
      handleOnSort
    },
    ref
  ) => {
    const [sortConfig, setSortConfig] = useState(
      headCells
        .map<any>((item) => {
          if (item.sort) {
            return {
              title: item.id,
              direction: null
            };
          }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    );

    const handleChangePage = (event: unknown, newPage: number) => {
      localStorage.setItem(
        'lastPage',
        JSON.stringify({
          lastPage: newPage <= 0 ? 0 : newPage - 1,
          pageUrl: window.location.href
        })
      );

      getTableData?.({
        page: newPage - 1,
        size: size,
        sorts: sortConfig
      });
    };

    const sort = (itemSort: any) => {
      if (!itemSort) return false;

      const data = sortConfig.map((item) => {
        if (item.title === itemSort) {
          let directionData: string | null = SortValues.ASC;
          if (item.direction === SortValues.ASC) {
            directionData = SortValues.DESC;
          }
          if (item.direction === SortValues.DESC) {
            directionData = null;
          }
          return {
            ...item,
            direction: directionData
          };
        }

        if (multisort) return item;
        return {
          ...item,
          direction: null
        };
      });
      setSortConfig(data);
      getTableData?.({
        page: page,
        size: size,
        sorts: data
      });
      if (handleOnSort) {
        handleOnSort(data);
      }
    };

    return (
      <div
        className={clsx(style.tableWrap, tableWrapClassName)}
        data-test-id={testIdAttr}
        ref={ref}
      >
        {columns && columns.length > 0 && (
          <TableContainer>
            <TableMaterial>
              <TableHead>
                <TableRow>
                  {headCells.map((item) => (
                    <TableCell
                      className={clsx(
                        item.sort && style.pointer,
                        item.isMustHide && style.hide,
                        item.className
                      )}
                      onClick={() => item.sort && !item.disabled && sort(item.id)}
                      data-disabled={item.disabled}
                      key={item.id}
                    >
                      {item.tooltip ? (
                        <Tooltip
                          blackTooltip={item.tooltip.type === 'dark'}
                          placement={item.tooltip.placement}
                          toolTipText={item.tooltip.text}
                          followCursor={item.tooltip.type === 'dark'}
                        >
                          <span className={style.thWrapper}>
                            {item.label}
                            {item.sort && (
                              <span className={style.iconWrapper}>
                                {sortConfig.find((sortItem) => sortItem.title === item.id)
                                  ?.direction !== SortValues.DESC && (
                                  <span className={clsx('material-icons', style.icon)}>
                                    expand_more
                                  </span>
                                )}
                                {sortConfig.find((sortItem) => sortItem.title === item.id)
                                  ?.direction !== SortValues.ASC && (
                                  <span
                                    className={clsx('material-icons', style.icon, style.activeIcon)}
                                  >
                                    expand_more
                                  </span>
                                )}
                              </span>
                            )}
                          </span>
                        </Tooltip>
                      ) : (
                        <span className={style.thWrapper}>
                          {item.label}
                          {item.sort && (
                            <span className={style.iconWrapper}>
                              {sortConfig.find((sortItem) => sortItem.title === item.id)
                                ?.direction !== SortValues.DESC && (
                                <span className={clsx('material-icons', style.icon)}>
                                  expand_more
                                </span>
                              )}
                              {sortConfig.find((sortItem) => sortItem.title === item.id)
                                ?.direction !== SortValues.ASC && (
                                <span
                                  className={clsx('material-icons', style.icon, style.activeIcon)}
                                >
                                  expand_more
                                </span>
                              )}
                            </span>
                          )}
                        </span>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{columns.map((item: any) => item)}</TableBody>
            </TableMaterial>
          </TableContainer>
        )}
        {isLoading && (!columns.length || !columns) && (
          <div className={style.spinner}>
            <LoadingSpinner />
          </div>
        )}
        {columns && columns.length > 0 && Math.ceil(totalNumber / size) > 1 && (
          <div className={style.paginationWrap} data-test-id="table-pagination">
            <Pagination
              className={style.paginationNumbers}
              count={Math.ceil(totalNumber / size)}
              page={page + 1}
              onChange={handleChangePage}
              shape="rounded"
            />
          </div>
        )}
      </div>
    );
  }
);

export default Table;
