import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getLsUserRoleObject,
  getRoadmapsConfig,
  setRoadmapsConfig
} from 'modules/networkTools/localStorageTokens';
import { notifyUserError, notifyUserSuccess } from 'utils/notifications';

import {
  postCreateTodo,
  getTodos,
  patchUpdateTodoStatus,
  putUpdateTodo,
  getGoals,
  updateTodoOrder,
  updateGoalOrder,
  putNewTodoDraft,
  getNewTodoDraft,
  deleteNewTodoDraft,
  postCreateGoal,
  putNewGoalDraft,
  deleteNewGoalDraft,
  patchChangeGoalStatus,
  putUpdateTodoDraft,
  deleteTodoDraft,
  putUpdateGoal,
  putGoalDraft,
  getNewGoalDraft,
  deleteGoalDraft
} from './roadmapsActions';

export type View = 'todos' | 'goals';

export type Status = 'ACTIVE' | 'COMPLETED' | 'ABANDONED';

export type CommonTodo = {
  updatedAt?: string;
  createdAt?: string;
  id: number | null;
  goalId: number | null;
  title: string;
  status: 'ACTIVE' | 'COMPLETED' | 'ABANDONED';
  date?: string | null;
  startDateTime?: string | null;
  endDateTime?: string | null;
  location: string | null;
  note: string | null;
  color: string;
  orderNum?: number | null;
  client: {
    name: string;
    id: number;
  } | null;
};

type DraftTodo = CommonTodo & {
  creationContext: 'ROADMAPS' | 'MY_TODOS' | 'GOALS';
  userId: number;
  userName: string;
};

export type Todo = CommonTodo & {
  draft: DraftTodo | null;
  newlyCreated?: boolean;
  statusUpdatedAt: string;
  previousStatus: Status;
};

export type CommonGoal = {
  title: string;
  date: string | null;
};

export type DraftGoal = CommonGoal & {
  userName: string;
  userId: number;
};

type Goal = CommonGoal & {
  id: number | string;
  todos: Todo[];
  draft: DraftGoal | null;
  newTodoDraft: DraftTodo | null;
  status: Status;
  statusUpdatedAt: string;
  expanded: boolean;
  orderNum: number;
};

type InitialState = {
  view: View;
  status: Status[];
  hasOpenedPopper: boolean;
  todos: Todo[];
  goals: Goal[];
  newTodoDraft: DraftTodo | null;
  newGoalDraft: CommonGoal | null;
};

const uncategorizedGoal: Goal = {
  id: 'uncategorizedGoal',
  title: 'No goal',
  todos: [],
  draft: null,
  newTodoDraft: null,
  date: null,
  status: 'ACTIVE',
  expanded: false,
  orderNum: 0,
  statusUpdatedAt: ''
};

const initialState: InitialState = {
  view: 'goals',
  status: ['ACTIVE'],
  newTodoDraft: null,
  newGoalDraft: null,
  hasOpenedPopper: false,
  todos: [],
  goals: [uncategorizedGoal]
};

export const roadmapsSlice = createSlice({
  name: 'roadmapsSlice',
  initialState,
  reducers: {
    setView: (state, action) => {
      state.view = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setHasOpenedPopper: (state, action: PayloadAction<{ opened: boolean }>) => {
      state.hasOpenedPopper = action.payload.opened;
    },
    updateTodo: (state, action: PayloadAction<{ todo: Todo }>) => {
      const { todo } = action.payload;
      const index = state.todos.findIndex((t) => t.id === todo.id);
      if (index !== -1) {
        state.todos[index] = todo;
      }
    },
    setGoalExpanded: (
      state,
      action: PayloadAction<{ goalId: string | number | null; expanded: boolean }>
    ) => {
      if (action.payload.goalId) {
        const goalIndex = state.goals.findIndex((goal) => goal.id === action.payload.goalId);
        if (goalIndex !== -1) {
          state.goals[goalIndex].expanded = action.payload.expanded;
        }
      } else {
        const uncategorizedGoalIndex = state.goals.findIndex((goal) => goal.id == null);
        if (uncategorizedGoalIndex !== -1) {
          state.goals[uncategorizedGoalIndex].expanded = action.payload.expanded;
        }
      }

      const goalsExpanded = state.goals.reduce((acc, goal) => {
        acc[goal.id] = goal.expanded;
        return acc;
      }, {} as Record<string, boolean>);

      setRoadmapsConfig({
        view: state.view,
        status: state.status,
        goalsExpanded
      });
    },
    setAllGoalsExpanded: (state, action: PayloadAction<boolean>) => {
      state.goals.forEach((goal) => {
        goal.expanded = action.payload;
      });
    },
    clearNewlyCreated: (state, action: PayloadAction<number>) => {
      const todo = state.todos.find((t) => t.id === action.payload);
      if (todo) {
        todo.newlyCreated = false;
      }
    },
    createNewTodo: (state, action: PayloadAction<{ goalId: number }>) => {
      const goalIndex = state.goals.findIndex((g) => g.id === action.payload.goalId);
      if (goalIndex !== -1) {
        state.goals[goalIndex].newTodoDraft = {
          creationContext: 'ROADMAPS',
          goalId: action.payload.goalId,
          title: '',
          status: 'ACTIVE',
          orderNum: null,
          id: null,
          client: null,
          userId: getLsUserRoleObject().userId,
          userName: getLsUserRoleObject().name,
          date: null,
          startDateTime: null,
          endDateTime: null,
          location: null,
          note: null,
          color: '#03A59D'
        };
      }
    },
    clearNewTodoGoalDraft: (state, action: PayloadAction<number>) => {
      const goal = state.goals.find((g) => g.id === action.payload);
      if (goal) {
        goal.newTodoDraft = null;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTodos.fulfilled, (state, { payload }) => {
      state.todos = payload;
    });
    builder
      .addCase(postCreateTodo.fulfilled, (state, { payload }) => {
        let smallestOrderNum: undefined | number;

        const activeTodos = state.todos.filter((todo) => todo.status === 'ACTIVE');

        if (activeTodos.length === 0) {
          smallestOrderNum = 2;
        } else {
          smallestOrderNum = Math.min(...activeTodos.map((todo) => todo.orderNum ?? 0));
        }

        notifyUserSuccess('New To-do created.');

        state.todos.push({ ...payload, newlyCreated: true, orderNum: smallestOrderNum - 1 });
        state.newTodoDraft = null;
      })
      .addCase(patchUpdateTodoStatus.pending, (state, { payload, meta }) => {
        const index = state.todos.findIndex((todo) => todo.id === meta.arg.id);
        if (index !== -1) {
          state.todos[index].status = meta.arg.status;
          state.todos[index].statusUpdatedAt = new Date().toISOString();
        }

        if (meta.arg.status === 'COMPLETED') {
          notifyUserSuccess('To-do was completed.');
        }

        if (meta.arg.status === 'ABANDONED') {
          notifyUserSuccess('To-do was abandoned.');
        }

        if (meta.arg.status === 'ACTIVE') {
          notifyUserSuccess('To-do was reactivated.');
        }

        if (meta.arg.status === 'ACTIVE') {
          const activeTodos = state.todos.filter((todo) => todo.status === 'ACTIVE');
          let smallestOrderNum: undefined | number;
          if (activeTodos.length === 0) {
            smallestOrderNum = 2;
          } else {
            smallestOrderNum = Math.min(...activeTodos.map((todo) => todo.orderNum ?? 0));
          }

          if (index !== -1) {
            state.todos[index].orderNum = smallestOrderNum - 1;
          }
        }
      })
      .addCase(putUpdateTodo.fulfilled, (state, { payload }) => {
        const index = state.todos.findIndex((todo) => todo.id === payload.id);

        if (index !== -1) {
          state.todos[index] = { ...payload, draft: null };
        }
      })
      .addCase(putUpdateTodo.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getGoals.pending, (state) => {
        state.goals = [uncategorizedGoal];
      })
      .addCase(getGoals.fulfilled, (state, { payload }) => {
        const goals = payload
          .map((goal: Goal) => {
            const goalTodos = state.todos.filter((todo) => todo.goalId === goal.id);
            const hasDraftTodo = goalTodos.some(
              (todo) =>
                todo.draft?.userId === getLsUserRoleObject().userId &&
                (todo.draft?.creationContext === 'GOALS' ||
                  todo.draft?.creationContext === 'ROADMAPS')
            );
            const hasNewTodoDraft = Boolean(goal.newTodoDraft);

            return {
              ...goal,
              expanded:
                getRoadmapsConfig().goalsExpanded?.[goal.id] ||
                hasDraftTodo ||
                hasNewTodoDraft ||
                false,
              title: goal.id ? goal.title : 'No goal'
            };
          })
          .filter((goal: Goal) => goal.id);

        state.goals = [...state.goals, ...goals];
      })
      .addCase(updateTodoOrder.pending, (state, { meta }) => {
        meta.arg.todos.forEach((todo) => {
          const index = state.todos.findIndex((t) => t.id === todo.id);
          if (index !== -1) {
            state.todos[index].orderNum = todo.orderNum;
          }
        });
      })
      .addCase(updateGoalOrder.pending, (state, { meta }) => {
        meta.arg.goals.forEach((goal) => {
          const index = state.goals.findIndex((g) => g.id === goal.id);
          if (index !== -1) {
            state.goals[index].orderNum = goal.orderNum;
          }
        });
      })
      .addCase(putNewTodoDraft.fulfilled, (state, { payload, meta }) => {
        const newTodoDraft = {
          id: payload.id,
          client: payload.client,
          title: meta.arg.title,
          status: 'ACTIVE' as Status,
          startDateTime: meta.arg.startDateTime,
          endDateTime: meta.arg.endDateTime,
          date: meta.arg.date,
          location: meta.arg.location,
          note: meta.arg.note,
          color: meta.arg.color,
          creationContext: meta.arg.creationContext,
          userId: getLsUserRoleObject().userId,
          goalId: meta.arg.goalId,
          userName: getLsUserRoleObject().name
        };
        if (meta.arg.creationContext === 'GOALS') {
          const goal = state.goals.find((g) => g.id === meta.arg.goalId);
          if (goal) {
            goal.newTodoDraft = newTodoDraft;
            return;
          }
        }
        if (meta.arg.creationContext === 'ROADMAPS') {
          state.newTodoDraft = newTodoDraft;
        }
      })
      .addCase(getNewTodoDraft.fulfilled, (state, { payload }) => {
        if (payload == null || payload === '') {
          state.newTodoDraft = null;
          return;
        }
        state.newTodoDraft = payload;
      })
      .addCase(deleteNewTodoDraft.pending, (state, { meta }) => {
        if (meta.arg.creationContext === 'GOALS') {
          const goal = state.goals.find((g) => g.id === meta.arg.goalId);
          if (goal) {
            goal.newTodoDraft = null;
          }
        }
        if (meta.arg.creationContext === 'ROADMAPS') {
          state.newTodoDraft = null;
        }
      })
      .addCase(deleteNewTodoDraft.fulfilled, (state) => {
        state.newTodoDraft = null;
      })
      .addCase(putNewTodoDraft.rejected, (_, { payload }: any) => {
        if (payload) {
          notifyUserError(payload);
        }
      })
      .addCase(postCreateGoal.fulfilled, (state, { payload }) => {
        // find lowest goal order num
        notifyUserSuccess('New Goal created.');
        const activeGoals = state.goals.filter((goal) => goal.status === 'ACTIVE');
        const lowestOrderNum = Math.min(...activeGoals.map((goal) => goal.orderNum));
        state.goals.push({
          ...payload,
          orderNum: lowestOrderNum - 1,
          expanded: false,
          draft: null
        });
      })
      .addCase(postCreateGoal.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putUpdateGoal.fulfilled, (state, { payload }) => {
        const index = state.goals.findIndex((goal) => goal.id === payload.id);
        if (index !== -1) {
          state.goals[index] = { ...state.goals[index], ...payload, draft: null };
        }
      })
      .addCase(putUpdateGoal.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putNewGoalDraft.fulfilled, (state, { payload }) => {
        state.newGoalDraft = payload;
      })
      .addCase(putNewGoalDraft.rejected, (_, { payload }: any) => {
        if (payload) {
          notifyUserError(payload);
        }
      })
      .addCase(deleteNewGoalDraft.pending, (state) => {
        state.newGoalDraft = null;
      })
      .addCase(patchChangeGoalStatus.pending, (state, { meta }) => {
        const hasTodos = state.todos.some((todo) => todo.goalId === meta.arg.id);
        if (meta.arg.status === 'ACTIVE') {
          if (hasTodos) {
            notifyUserSuccess('Goal and To-dos were reactivated.');
          } else {
            notifyUserSuccess('Goal was reactivated.');
          }
        }

        if (meta.arg.status === 'COMPLETED') {
          if (hasTodos) {
            notifyUserSuccess('Goal and To-dos were completed.');
          } else {
            notifyUserSuccess('Goal was completed.');
          }
        }

        if (meta.arg.status === 'ABANDONED') {
          if (hasTodos) {
            notifyUserSuccess('Goal and To-dos were abandoned.');
          } else {
            notifyUserSuccess('Goal was abandoned.');
          }
        }

        const index = state.goals.findIndex((goal) => goal.id === meta.arg.id);
        if (index !== -1) {
          const newGoalStatus = meta.arg.status;
          state.goals[index].status = newGoalStatus;
          state.goals[index].statusUpdatedAt = new Date().toISOString();

          if (newGoalStatus === 'ACTIVE') {
            const activeGoals = state.goals.filter((goal) => goal.status === 'ACTIVE');
            let smallestOrderNum: undefined | number;
            if (activeGoals.length === 0) {
              smallestOrderNum = 2;
            } else {
              smallestOrderNum = Math.min(...activeGoals.map((goal) => goal.orderNum));
            }

            state.goals[index].orderNum = smallestOrderNum - 1;

            state.todos.forEach((todo) => {
              if (todo.goalId === meta.arg.id) {
                todo.status = todo.previousStatus;
              }
            });

            return;
          }

          if (newGoalStatus === 'ABANDONED' || newGoalStatus === 'COMPLETED') {
            if (state.newTodoDraft?.goalId === meta.arg.id) {
              state.newTodoDraft.goalId = null;
            }
            state.todos.forEach((todo) => {
              if (todo.goalId === meta.arg.id) {
                todo.previousStatus = todo.status;
                if (todo.status === 'ACTIVE') {
                  todo.status = newGoalStatus;
                }
              }
            });
          }
        }
      })
      .addCase(putUpdateTodoDraft.fulfilled, (state, { payload, meta }) => {
        const todoIndex = state.todos.findIndex((todo) => todo.id === meta.arg.id);
        if (todoIndex !== -1) {
          state.todos[todoIndex] = { ...state.todos[todoIndex], draft: payload };
        }
      })
      .addCase(deleteTodoDraft.pending, (state, { meta }) => {
        const index = state.todos.findIndex((todo) => todo.id === meta.arg.id);
        if (index !== -1) {
          state.todos[index].draft = null;
        }
      })
      .addCase(putGoalDraft.fulfilled, (state, { payload, meta }) => {
        const index = state.goals.findIndex((goal) => goal.id === meta.arg.id);
        if (index !== -1) {
          state.goals[index] = {
            ...state.goals[index],
            draft: {
              ...payload,
              userId: getLsUserRoleObject().userId,
              userName: getLsUserRoleObject().name
            }
          };
        }
      })
      .addCase(getNewGoalDraft.fulfilled, (state, { payload }) => {
        state.newGoalDraft = payload;
      })
      .addCase(deleteGoalDraft.pending, (state, { meta }) => {
        const index = state.goals.findIndex((goal) => goal.id === meta.arg.id);
        if (index !== -1) {
          state.goals[index].draft = null;
        }
      });
  }
});

export const {
  setView,
  setStatus,
  setHasOpenedPopper,
  updateTodo,
  setGoalExpanded,
  setAllGoalsExpanded,
  clearNewlyCreated,
  createNewTodo,
  clearNewTodoGoalDraft
} = roadmapsSlice.actions;

export default roadmapsSlice.reducer;
