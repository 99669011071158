import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import ApexCharts from 'react-apexcharts';
import {
  HealthTrackerCategoryItem,
  HealthTrackerMetricListItem
} from 'store/healthTracker/healthTrackerSlice';

interface Props {
  content: HealthTrackerMetricListItem[];
  selectedCategory: HealthTrackerCategoryItem;
}

const Graph: FC<Props> = ({ content, selectedCategory }) => {
  const [timeSeriesState, setTimeSeriesState] = useState<any>([]);
  const sortedContent = [...content].sort((a, b) => {
    const aTime = a.entryTime
      ? dayjs(a.entryTime).valueOf()
      : dayjs(a.entryDate).startOf('day').valueOf();
    const bTime = b.entryTime
      ? dayjs(b.entryTime).valueOf()
      : dayjs(b.entryDate).startOf('day').valueOf();
    return aTime - bTime;
  });

  const processedData = sortedContent.reduce(
    (acc: { x: number; y: number; hasEntryTime: boolean; originalTimestamp: number }[], item) => {
      let baseTimestamp = item.entryTime
        ? dayjs(item.entryTime).valueOf()
        : dayjs(item.entryDate).startOf('day').valueOf();

      const sameTimestampEntries = acc.filter((entry) => entry.x === baseTimestamp);

      if (sameTimestampEntries.length > 0) {
        const offset = sameTimestampEntries.length * 1;
        baseTimestamp -= offset;
      }

      acc.push({
        x: baseTimestamp,
        y: item.value,
        hasEntryTime: !!item.entryTime,
        originalTimestamp: item.entryTime
          ? dayjs(item.entryTime).valueOf()
          : dayjs(item.entryDate).endOf('day').valueOf()
      });
      return acc;
    },
    []
  );

  // const chartSeries = [
  //   {
  //     name: selectedCategory.name,
  //     data: processedData
  //   }
  // ];

  useEffect(() => {
    const chartSeries = [
      {
        name: selectedCategory.name,
        data: processedData
      }
    ];
    setTimeSeriesState(chartSeries);
  }, [JSON.stringify(content), selectedCategory.name]);

  // const chartSeries = [
  //   {
  //     name: selectedCategory.name,
  //     data: content.map((a) => ({
  //       x: dayjs(a.entryDate).valueOf(),
  //       y: a.value
  //     }))
  //   }
  // ];

  const colorStops = (() => {
    const { direction } = selectedCategory;

    const svgGradientStopsDecrease = [
      { offset: 0, color: '#FF8C07', opacity: 1 },
      { offset: 0.334339 * 100, color: '#EDE890', opacity: 1 },
      { offset: 0.589141 * 100, color: '#AFE5B6', opacity: 1 },
      { offset: 0.793106 * 100, color: '#7CCBA6', opacity: 1 },
      { offset: 100, color: '#3AA0B6', opacity: 1 }
    ];

    const svgGradientStopsIncrease = [
      { offset: 0, color: '#3AA0B6', opacity: 1 },
      { offset: 0.334339 * 100, color: '#7CCBA6', opacity: 1 },
      { offset: 0.589141 * 100, color: '#AFE5B6', opacity: 1 },
      { offset: 0.793106 * 100, color: '#EDE890', opacity: 1 },
      { offset: 100, color: '#FF8C07', opacity: 1 }
    ];

    return direction === 'DECREASE' ? svgGradientStopsDecrease : svgGradientStopsIncrease;
  })();

  const values = processedData.map((d) => d.y);
  const allValuesSame = values.every((val) => val === values[0]);
  const dates = processedData.map((d) => d.x);
  const minDate = Math.min(...dates);
  const maxDate = Math.max(...dates);
  const range = maxDate - minDate;
  // const padding = range * 0.1;
  const singleValue = content.length === 1 ? values[0] : null;

  // const isSameMonth =
  //   processedData.length > 0 &&
  //   new Set(processedData.map((item) => dayjs(item.x).format('YYYY-MM'))).size === 1;

  const yAxisMin = content.length === 1 && singleValue !== null ? singleValue - 10 : undefined;
  const yAxisMax = content.length === 1 && singleValue !== null ? singleValue + 10 : undefined;
  const getDatetimeFormatter = (rangeInDays: number) => {
    return {
      year: 'MMM yyyy',
      month: 'MMM yyyy',
      day: 'MMM d',
      hour: 'HH:mm',
      minute: 'HH:mm',
      second: 'HH:mm'
    };
  };

  // console.warn(
  //   padding,
  //   isSameMonth
  // );

  const chartOptions = {
    chart: {
      id: 'line-chart',
      type: 'line' as const,
      toolbar: {
        show: true,
        tools: {
          download: `<span class="material-icons" title="Download" style="color: #60768F">download</span>`,
          selection: false,
          zoom: false,
          zoomin: `<span class="material-icons" title="Zoom In" style="color: ${
            content.length === 1 ? '#CBD4E1' : '#60768F'
          }; cursor: ${content.length === 1 ? 'default' : 'pointer'}">add</span>`,
          zoomout: `<span class="material-icons" title="Zoom Out" style="color: ${
            content.length === 1 ? '#CBD4E1' : '#60768F'
          }; cursor: ${content.length === 1 ? 'default' : 'pointer'}">remove</span>`,
          pan: true,
          reset: `<span class="material-icons" title="Reset" style="color: ${
            content.length === 1 ? '#CBD4E1' : '#60768F'
          }; cursor: ${content.length === 1 ? 'default' : 'pointer'}">restart_alt</span>`
        },
        autoSelected: 'pan' as const,
        export: {
          png: { filename: `${selectedCategory.name}_${dayjs().format('MM-DD-YYYY')}` },
          csv: {
            filename: `${selectedCategory.name}_${dayjs().format('MM-DD-YYYY')}`,
            columnDelimiter: ',',
            headerCategory: 'Date',
            headerValue: selectedCategory.name,
            categoryFormatter: (timestamp: number) => {
              return dayjs(timestamp).format('MM/DD/YYYY');
            }
          }
        }
      },
      background: 'transparent',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradients: true,
        dynamicAnimation: { speed: 350 }
      }
    },
    zoom: {
      enabled: content.length !== 1,
      type: 'x' as const,
      autoScaleYaxis: content.length !== 1,
      allowMouseWheelZoom: content.length !== 1
    },
    pan: {
      enabled: content.length !== 1
    },
    stroke: {
      curve: content.length === 2 ? ('straight' as const) : ('smooth' as const),
      width: 2
    },
    markers: {
      size: content.length === 1 ? 6 : 0,
      colors: ['#3AA0B6'],
      hover: {
        size: 6
      },
      offsetX: content.length === 1 ? -5 : 0
    },
    tooltip: {
      enabled: true,
      // theme: 'dark',
      shared: true,
      followCursor: content.length === 2 && true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
        const data = w.config.series[seriesIndex].data[dataPointIndex];
        const date = dayjs(data.x).format('MM/DD/YY');
        const value = data.y;
        return `
          <div style="display: flex; align-items: center; flex-direction: column">
            <p>${date}</p>
            <p>${value}</p>
          </div>
        `;
      }
    },
    grid: {
      show: false,
      padding: { left: 15, top: 15, right: 15 },
      floating: true
    },
    xaxis: {
      type: 'datetime' as const,
      tickPlacement: 'on' as const,
      min: content.length === 1 ? minDate - 1000 * 60 * 60 * 24 : minDate,
      max: content.length === 1 ? maxDate + 1000 * 60 * 60 * 24 : maxDate,
      tickAmount: content.length === 1 ? 1 : undefined,
      axisBorder: { show: false },
      axisTicks: { show: false },
      crosshairs: { show: false },
      tooltip: { enabled: false },
      padding: { left: 15, right: 15 },
      labels: {
        show: true,
        showDuplicates: false,
        offsetX: content.length === 1 ? 33 : 0,
        style: {
          colors: '#60768F',
          fontSize: '12px',
          fontFamily: 'Proxima Nova, sans-serif',
          fontWeight: '500',
          lineHeight: '140%'
        },
        datetimeFormatter:
          content.length === 1 ? undefined : getDatetimeFormatter(range / (1000 * 60 * 60 * 24)),
        formatter:
          content.length === 1
            ? (_value: string, timestamp?: number) => {
                // Используем timestamp для получения даты
                if (timestamp && processedData[0]) {
                  if (timestamp) {
                    return dayjs(timestamp).add(1, 'day').format('MMM D');
                  }
                  return '';
                }
                return '';
              }
            : undefined
      }
    },
    yaxis: {
      min: yAxisMin,
      max: yAxisMax,
      forceNiceScale: content.length !== 1,
      axisBorder: { show: false },
      axisTicks: { show: false },
      labels: { show: false }
    },
    fill: {
      type: allValuesSame ? 'solid' : 'gradient',
      colors: allValuesSame ? ['#7CCBA6'] : undefined,
      gradient: allValuesSame
        ? undefined
        : {
            shade: 'light',
            type: 'vertical',
            shadeIntensity: 1,
            opacityFrom: 1,
            opacityTo: 1,
            colorStops: colorStops
          }
    },
    legend: { show: false }
  };

  return (
    <ApexCharts
      options={chartOptions}
      series={timeSeriesState}
      type="line"
      height="100%"
      width="253px"
    />
  );
};

export default Graph;
