import { lazy } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Suspense } from 'react';

import { Props } from './noteEdit';

// // Lazy load the components
const NoteEditNew = lazy(() => import('./noteEdit'));
const NoteEditOld = lazy(() => import('./noteEditOld'));

const NoteEdit = (props: JSX.IntrinsicAttributes & Props) => {
  const { enablePhpMax } = useFlags();

  const NoteEditLazy = enablePhpMax ? NoteEditNew : NoteEditOld;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <NoteEditLazy {...props} />
    </Suspense>
  );
};

export default NoteEdit;
