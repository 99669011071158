import clsx from 'clsx';
import React from 'react';

import styles from './heading.module.scss';

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;
interface HeadingProps {
  level?: HeadingLevel;
  children: React.ReactNode;
  as?: keyof JSX.IntrinsicElements;
  className?: string;
  style?: React.CSSProperties;
  color?: 'light' | 'normal' | 'dark';
}

export const Heading: React.FC<HeadingProps> = ({
  level = 1,
  children,
  className,
  as,
  style,
  color = 'normal'
}) => {
  const Tag = as || (`h${level}` as keyof JSX.IntrinsicElements);

  return (
    <Tag
      className={clsx(styles.heading, styles[`h${level}`], styles[color], className)}
      style={style}
    >
      {children}
    </Tag>
  );
};

export default Heading;
