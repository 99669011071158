import {
  DatePicker,
  ErrorContainer,
  LabelComponent,
  Stack,
  StandardCheckbox,
  TimePicker
} from 'components';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { AddTodoFormValues } from 'modules/myTodos/addTodoForm/addTodoForm';

dayjs.extend(isSameOrAfter);

type Context = 'GOAL' | 'TODO';

type Props = {
  disabled?: boolean;
  onDateChange?: (date: string) => void;
  context?: Context;
};

const EventForm = ({ disabled, onDateChange, context = 'TODO' }: Props) => {
  const {
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    setValues,
    values
  } = useFormikContext<AddTodoFormValues>();

  return (
    <Stack pb={errors.endTime || errors.startTime ? '16px' : '0px'}>
      <Stack mb={context === 'TODO' ? '16px' : '0px'}>
        <LabelComponent text={values.hasDifferentEndDate ? 'Start Date' : 'Date'} mandatory />
        <DatePicker
          disabled={disabled}
          onChange={(date: string) => {
            onDateChange?.(date);

            // @ts-expect-error .then exists on setFieldValue, types are incorrect
            setFieldValue('date', date).then(() => {
              setFieldTouched('date', true);
              setFieldTouched('startTime', true);
              setFieldTouched('endTime', true);
            });
          }}
          onBlur={() => {
            setFieldTouched('date');
          }}
          value={values.date || ''}
          placeholder="YYYY-MM-DD"
        />
        <ErrorContainer
          errors={errors}
          name="date"
          visible={errors.date && touched.date}
          autoHeight
        />
        {context === 'TODO' && (
          <Stack mt="10px">
            <StandardCheckbox
              disabled={disabled}
              value={values.hasDifferentEndDate}
              onChange={(event) => {
                if (!event.target.checked) {
                  // @ts-expect-error .then exists on setValues, types are incorrect
                  setValues({ ...values, endDate: null, startTime: null, endTime: null }).then(
                    () => {
                      setFieldTouched('startTime');
                      setFieldTouched('endTime');
                    }
                  );
                } else {
                  // @ts-expect-error .then exists on setValues, types are incorrect
                  setValues({ ...values, endDate: values.date }).then(() => {
                    setFieldTouched('date');
                    setFieldTouched('endDate');
                    setFieldTouched('startTime');
                    setFieldTouched('endTime');
                  });
                }
                handleChange(event);
              }}
              onBlur={handleBlur}
              label="Add an End Date"
              name="hasDifferentEndDate"
            />
          </Stack>
        )}
      </Stack>
      {values.hasDifferentEndDate && context === 'TODO' && (
        <Stack mb="16px">
          <LabelComponent text="End Date" mandatory />
          <DatePicker
            disabled={disabled}
            onChange={(date: string) => {
              // @ts-expect-error .then exists on setValues, types are incorrect
              setValues({ ...values, endDate: date }).then(() => {
                setFieldTouched('endDate', true);
              });
            }}
            onBlur={() => {
              setFieldTouched('endDate');
            }}
            minDate={values.date ? dayjs(values.date) : undefined}
            value={values.endDate}
            placeholder="YYYY-MM-DD"
          />
          <ErrorContainer
            errors={errors}
            name="endDate"
            visible={errors.endDate && touched.endDate}
            autoHeight
          />
        </Stack>
      )}
      {context === 'TODO' && (
        <Stack display="flex" flexDirection="row" gap="12px">
          <Stack width="100%">
            <LabelComponent text="Start Time" mandatory={values.hasDifferentEndDate} />
            <TimePicker
              disabled={disabled}
              name="startTime"
              onChange={(date: string) => {
                // @ts-expect-error .then exists on setFieldValue, types are incorrect
                setFieldValue('startTime', date === 'Invalid Date' ? null : date).then(() => {
                  setFieldTouched('startTime');
                  setFieldTouched('endTime');
                  setFieldTouched('date');
                });
              }}
              onBlur={() => {
                setFieldTouched('startTime');
              }}
              value={values.startTime}
              showIcon={false}
            />
            <ErrorContainer
              visible={errors.startTime && touched.startTime}
              errors={errors}
              name="startTime"
              autoHeight
            />
          </Stack>
          <Stack width="100%">
            <LabelComponent text="End Time" mandatory={values.hasDifferentEndDate} />
            <TimePicker
              disabled={disabled}
              name="endTime"
              onChange={(date: string) => {
                // @ts-expect-error .then exists on setFieldValue, types are incorrect
                setFieldValue('endTime', date === 'Invalid Date' ? null : date).then(() => {
                  setFieldTouched('endTime');
                  setFieldTouched('startTime');
                  setFieldTouched('date');
                });
              }}
              onBlur={() => {
                setFieldTouched('endTime');
              }}
              value={values.endTime}
              showIcon={false}
            />
            <ErrorContainer
              visible={errors.endTime && touched.endTime}
              errors={errors}
              name="endTime"
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default EventForm;
