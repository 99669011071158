import { Button } from '@mui/material';
import React, { useState } from 'react';
import { OcrScannerAudio } from '.';

const OcrRecorderAudioFixedButton: React.FunctionComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleRecorder = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '90px',
        right: '10px',
        zIndex: 100,
        // background: 'red',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px'
      }}
    >
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#188aa1',
          color: 'white',
          borderRadius: '80px',
          width: '60px',
          marginLeft: 'auto'
        }}
        onClick={toggleRecorder}
      >
        <span className="material-icons">{'mic'}</span>
      </Button>
      {isOpen && (
        <div
          style={{
            // position: 'fixed',
            // bottom: '50px',
            // right: '10px',
            // zIndex: 100,
            backgroundColor: 'white',
            padding: '10px',
            border: '1px solid black',
            transform: 'scale(0.8)',
            marginLeft: 'auto',
            position: 'fixed',
            right: '-17px',
            bottom: '120px'
          }}
        >
          <OcrScannerAudio />
        </div>
      )}
    </div>
  );
};

export default OcrRecorderAudioFixedButton;
