import { ComponentProps, forwardRef, useRef, useState, useEffect } from 'react';
import { AnimatePresence, motion, useAnimate } from 'framer-motion';
import { useFormikContext } from 'formik';
import { AddTodoFormValues } from 'modules/myTodos/addTodoForm/addTodoForm';
import clsx from 'clsx';
import { ErrorContainer, Stack } from 'components';

import style from './titleInput.module.scss';

type InputProps = {
  expandable: false;
} & ComponentProps<'input'>;

type TextAreaProps = {
  expandable: true;
  wrapperClassName?: string;
} & ComponentProps<'textarea'>;

type Props = InputProps | TextAreaProps;

const Error = forwardRef<HTMLDivElement, { titleError: string | null }>(({ titleError }, ref) => {
  return (
    <AnimatePresence>
      {titleError && (
        <motion.div
          id="title-error"
          ref={ref}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.2, ease: 'easeIn', bounce: 0 }}
          viewport={{ once: true }}
          style={{ position: 'relative', overflowY: 'hidden' }}
        >
          <ErrorContainer errors={{ title: titleError }} visible={titleError} name="title" />
        </motion.div>
      )}
    </AnimatePresence>
  );
});

const TitleInput = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { values, handleChange, handleBlur, handleSubmit, isSubmitting } =
    useFormikContext<AddTodoFormValues>();
  const [scope, animate] = useAnimate();
  const [titleError, setTitleError] = useState<string | null>(null);
  const errorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSubmitting) {
      setTitleError(null);
    }
  }, [isSubmitting]);

  if (props.expandable) {
    return (
      <div
        ref={scope}
        data-replicated-value={values.title}
        className={clsx(style.growWrap, props.wrapperClassName)}
      >
        <textarea
          {...props}
          rows={1}
          ref={ref}
          name="title"
          onChange={(e) => {
            const value = e.target.value;
            const clippedSnapshot = value.slice(0, 120);
            if (value.length > 120) {
              setTitleError('Character limit reached');
              animate(
                '#title-error',
                {
                  x: [-2, 2, -2, 2, 0]
                },
                { duration: 0.2, ease: 'easeIn', bounce: 0 }
              );
            } else {
              setTitleError(null);
            }
            handleChange({
              ...e,
              target: { ...e.target, value: clippedSnapshot, name: 'title' }
            });
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              if (isSubmitting) {
                return;
              }
              handleSubmit();
            }
          }}
          onBlur={handleBlur}
          placeholder={props.placeholder ?? 'Type to add a to-do or event'}
          className={clsx(style.todoInput, props.className)}
          value={values.title ?? ''}
        />
        <Error titleError={titleError} ref={errorRef} />
      </div>
    );
  }

  if (!props.expandable) {
    return (
      <Stack display="flex" width="100%">
        <input
          {...props}
          value={values.title ?? ''}
          name="title"
          onChange={(e) => {
            const value = e.target.value;
            const clippedSnapshot = value.slice(0, 120);
            if (value.length > 120) {
              setTitleError('Character limit reached');
              animate(
                scope.current,
                {
                  x: [-2, 2, -2, 2, 0]
                },
                { duration: 0.2, ease: 'easeIn', bounce: 0 }
              );
            } else {
              setTitleError(null);
            }
            handleChange({
              ...e,
              target: { ...e.target, value: clippedSnapshot, name: 'title' }
            });
          }}
          onBlur={handleBlur}
          className={clsx(style.todoInput, props.className)}
        />
        <Error titleError={titleError} ref={scope} />
      </Stack>
    );
  }

  return null;
});

export default TitleInput;
