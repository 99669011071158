// import { useState } from 'react';
import { LiveAudioVisualizer } from 'react-audio-visualize';

const CustomLiveAudioVisualizer = ({ mediaRecorder }: any) => {
  //   const [mediaRecorder] = useState<MediaRecorder>();

  // set media recorder somewhere in code

  return (
    <div>
      {mediaRecorder && (
        <LiveAudioVisualizer
          mediaRecorder={mediaRecorder}
          width={200}
          height={75}
          barColor={'#188aa1'}
        />
      )}
    </div>
  );
};

export default CustomLiveAudioVisualizer;
