import { createSlice } from '@reduxjs/toolkit';
import { notifyUserError, notifyUserSuccess } from 'utils/notifications';

import {
  deleteHealthTracker,
  getHealthTracker,
  getHealthTrackerCategory,
  patchHealthTrackerCategory,
  postHealthTracker,
  postHealthTrackerCategory,
  putHealthTracker
} from './healthTrackerActions';

export type SourceType = 'MEDICAL_RECORD' | 'IN_PERSON_MEASUREMENT' | 'SELF_REPORTED';
export type DirectionType = 'DECREASE' | 'INCREASE';

export type HealthTrackerCategoryItem = {
  id: number;
  name: string;
  unit?: string;
  direction?: DirectionType;
  primaryMetric: boolean;
  hasEntries?: boolean;
  code?: string | null;
};

export type HealthTrackerMetricListItem = {
  id: number;
  entryDate: string;
  entryTime: string;
  value: number;
  source: SourceType;
  unit: string;
  concerningValue: boolean;
};

type InitialState = {
  healthTrackerCategoryList: HealthTrackerCategoryItem[];
  healthTrackerMetricList: {
    content: HealthTrackerMetricListItem[];
    contentGraph: HealthTrackerMetricListItem[];
    page: number;
    size: number;
    sorts: { title: string; direction: string }[] | null;
  };
  healthTrackerMetricListLoading: boolean;
};

const initialState: InitialState = {
  healthTrackerCategoryList: [],
  healthTrackerMetricList: {
    content: [],
    contentGraph: [],
    page: 0,
    size: 99999,
    sorts: null
  },
  healthTrackerMetricListLoading: false
};

export const healthTrackerSlice = createSlice({
  name: 'healthTrackerSlice',
  initialState,
  reducers: {
    updateSortsMetricList: (state, action) => {
      state.healthTrackerMetricList.sorts = action.payload;
    },
    deleteHealthTrackerCategory: (state, action) => {
      const index = state.healthTrackerCategoryList.findIndex((a) => a.id === action.payload);
      if (index !== -1) {
        state.healthTrackerCategoryList[index].hasEntries = false;
      }
    },
    resetHealthTrackerMetricList: (state) => {
      state.healthTrackerCategoryList = [];
      state.healthTrackerMetricListLoading = false;
      state.healthTrackerMetricList = {
        content: [],
        contentGraph: [],
        page: 0,
        size: 99999,
        sorts: null
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHealthTracker.pending, (state, action) => {
        state.healthTrackerMetricListLoading = true;
      })
      .addCase(getHealthTracker.fulfilled, (state, action) => {
        state.healthTrackerMetricList.content = action.payload.data;
        if (!action.payload.sorts) {
          state.healthTrackerMetricList.contentGraph = action.payload.data;
        }
        state.healthTrackerMetricListLoading = false;
      })
      .addCase(getHealthTracker.rejected, (state, { payload }: any) => {
        state.healthTrackerMetricListLoading = false;
        notifyUserError(payload);
      })
      .addCase(postHealthTracker.fulfilled, (state, { payload }: any) => {
        const index = state.healthTrackerCategoryList.findIndex(
          (a) => a.id.toString() === payload.metricId
        );
        if (index !== -1) {
          state.healthTrackerCategoryList[index].hasEntries = true;
        }
        notifyUserSuccess('Your value was saved.');
      })
      .addCase(postHealthTracker.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putHealthTracker.fulfilled, (state, { payload }: any) => {
        const index = state.healthTrackerMetricList.content.findIndex(
          (a) => a.id === payload.entryId
        );
        if (index !== -1) {
          state.healthTrackerMetricList.content[index] = payload.data;
        }
        const indexGraph = state.healthTrackerMetricList.contentGraph.findIndex(
          (a) => a.id === payload.entryId
        );
        if (index !== -1) {
          state.healthTrackerMetricList.contentGraph[indexGraph] = payload.data;
        }
      })
      .addCase(putHealthTracker.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(deleteHealthTracker.fulfilled, (state, { payload }: any) => {
        const index = state.healthTrackerMetricList.content.findIndex((a) => a.id === payload);
        if (index !== -1) {
          state.healthTrackerMetricList.content.splice(index, 1);
        }
        const indexGraph = state.healthTrackerMetricList.contentGraph.findIndex(
          (a) => a.id === payload
        );
        if (index !== -1) {
          state.healthTrackerMetricList.contentGraph.splice(indexGraph, 1);
        }
      })
      .addCase(deleteHealthTracker.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getHealthTrackerCategory.fulfilled, (state, action) => {
        state.healthTrackerCategoryList = action.payload;
      })
      .addCase(getHealthTrackerCategory.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(postHealthTrackerCategory.fulfilled, (state, { payload }: any) => {
        state.healthTrackerCategoryList = [
          ...state.healthTrackerCategoryList,
          { ...payload.metric, hasEntries: true }
        ];
        notifyUserSuccess('Your value was saved.');
      })
      .addCase(postHealthTrackerCategory.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(patchHealthTrackerCategory.fulfilled, (state, { payload }: any) => {
        state.healthTrackerCategoryList.forEach((item) => {
          item.primaryMetric = false;
        });
        const index = state.healthTrackerCategoryList.findIndex((a) => a.id === payload.metricId);
        if (index !== -1) {
          state.healthTrackerCategoryList[index].primaryMetric = payload.type;
        }
      })
      .addCase(patchHealthTrackerCategory.rejected, (_, { payload }: any) => {
        // notifyUserError(payload);
      });
  }
});

export const { updateSortsMetricList, deleteHealthTrackerCategory, resetHealthTrackerMetricList } =
  healthTrackerSlice.actions;
export default healthTrackerSlice.reducer;
