import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { axiosPrivate } from '../../modules/networkTools/networkTools';
import { AboutPageProps, CadenceProps, IntegrationsPropsEMR } from './organizationSlice';

interface OrganizationsDTO {
  page?: number;
  size?: number;
  sorts?: { title: string; direction: string }[];
  statuses?: string[] | string;
  ids?: number | any;
}

interface UsersDTO extends OrganizationsDTO {
  id: string;
  activatePage?: boolean;
}

interface AutocompleteDTO {
  id?: string;
  maxResult: number;
  term: string;
}

export interface UserDTO {
  name: string;
  surname: string;
  email: string;
  programIds: number[];
  roleIds: number[];
  status: string;
  phoneNumber: string;
}

interface OrganizationSSO {
  organizationId: number | string;
  federated: boolean;
  auth0Connection: string;
}

export const getAboutOrganization: any = createAsyncThunk(
  'organization/getAboutOrganization',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`setup/organization/${id}`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postAboutPageCreate = createAsyncThunk(
  'organization/postAboutPageCreate',
  async (data: AboutPageProps, { rejectWithValue }) => {
    const url = 'setup/organization';
    try {
      const response = await axiosPrivate.post(url, data);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOrganizationsList: any = createAsyncThunk(
  'organization/getOrganizationsList',
  async ({ page, size, sorts, ids, statuses }: OrganizationsDTO, { rejectWithValue }) => {
    const defaultSort = '&sorts=updatedAt:desc';
    const idsString = ids ? `&ids=${ids}` : '';

    const statusesFiltered =
      statuses &&
      Array.isArray(statuses) &&
      statuses.length > 0 &&
      statuses.map((item) => `&statuses=${item}`).join('&');

    const statusesString = statusesFiltered ? statusesFiltered : '';
    let sortArray: string | string[] = '';
    if (sorts && sorts.length > 0) {
      sortArray = sorts
        .map((item) => item.direction && `&sorts=${item.title}:${item.direction}`)
        .join('');
    }

    try {
      const response = await axiosPrivate.get(
        `setup/organizations?page=${page}&size=${size}${
          sortArray || defaultSort
        }${idsString}${statusesString}`
      );
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOrganizationsListAutocomplete: any = createAsyncThunk(
  'organization/getOrganizationsListAutocomplete',
  async ({ maxResult, term }: AutocompleteDTO, { rejectWithValue }) => {
    let termData = term;
    if (term.includes(' ')) {
      termData = term.split(' ').reduce((previousValue, currentValue) => {
        if (currentValue === '') {
          return previousValue;
        }
        return `${previousValue}%20${currentValue}`;
      });
    }

    try {
      const response = await axiosPrivate.get(
        `setup/organization/autocomplete?maxResult=${maxResult}&term=${termData}`
      );
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putAboutPageCreate = createAsyncThunk(
  'organization/putAboutPageCreate',
  async (data: AboutPageProps, { rejectWithValue }) => {
    const url = 'setup/organization';
    try {
      const response = await axiosPrivate.put(url, data);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUsersList = createAsyncThunk(
  'organization/getUsersList',
  async ({ id, page, size, sorts, ids, statuses, activatePage }: UsersDTO, { rejectWithValue }) => {
    const defaultSort = '&activationDateTime:desc';
    const idsString = ids ? `&ids=${ids}` : '';
    const statusesFiltered = statuses ? `&${statuses}` : '&statuses=ACTIVE';

    let sortArray: string | string[] = '';
    if (sorts && sorts.length > 0) {
      sortArray = sorts
        .map((item) => item.direction && `&sorts=${item.title}:${item.direction}`)
        .join('');
    }

    try {
      const response = await axiosPrivate.get(
        `setup/organization/${id}/users?page=${page}&size=${size}${
          sortArray || defaultSort
        }${idsString}${activatePage ? '' : statusesFiltered}`
      );
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getProgramList = createAsyncThunk(
  'organization/getProgramList',
  async ({ id, page = 0, size = 99999, sorts, ids, statuses }: UsersDTO, { rejectWithValue }) => {
    const defaultSort = '&sorts=updatedAt:desc';
    const idsString = ids ? `&ids=${ids}` : '';
    const statusesFiltered = statuses ? `&${statuses}` : '';

    let sortArray: string | string[] = '';
    if (sorts && sorts.length > 0) {
      sortArray = sorts
        .map((item) => item.direction && `&sorts=${item.title}:${item.direction}`)
        .join('');
    }

    try {
      const response = await axiosPrivate.get(
        `setup/organization/${id}/programs?page=${page}&size=${size}${
          sortArray || defaultSort
        }${idsString}${statusesFiltered}`
      );
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getProgramListAutocomplete: any = createAsyncThunk(
  'organization/getProgramListAutocomplete',
  async ({ id, maxResult, term }: AutocompleteDTO, { rejectWithValue }) => {
    let termData = term;
    if (term.includes(' ')) {
      termData = term.split(' ').reduce((previousValue, currentValue) => {
        if (currentValue === '') {
          return previousValue;
        }
        return `${previousValue}%20${currentValue}`;
      });
    }

    try {
      const response = await axiosPrivate.get(
        `setup/organization/${id}/program/autocomplete?maxResult=${maxResult}&term=${termData}`
      );
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postUserCreate = createAsyncThunk(
  'organization/postUserCreate',
  async (
    { organizationID, user }: { organizationID: string; user: UserDTO },
    { rejectWithValue }
  ) => {
    const url = `setup/organization/${organizationID}/user`;
    try {
      const response = await axiosPrivate.post(url, user);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUser = createAsyncThunk(
  'organization/getUser',
  async (
    { organizationID, userId }: { organizationID: string; userId: string },
    { rejectWithValue }
  ) => {
    const url = `setup/organization/${organizationID}/user/${userId}`;
    try {
      const response = await axiosPrivate.get(url);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putUpdateUser = createAsyncThunk(
  'organization/putUpdateUser',
  async (
    {
      organizationID,
      userId,
      user
    }: {
      organizationID: string;
      userId: string;
      user: UserDTO;
    },
    { rejectWithValue }
  ) => {
    const url = `setup/organization/${organizationID}/user/${userId}`;
    try {
      const response = await axiosPrivate.put(url, user);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCheckUserEmail = createAsyncThunk(
  'organization/getCheckUserEmail',
  async (
    {
      email,
      organizationID
    }: {
      email: string;
      organizationID: string;
    },
    { rejectWithValue }
  ) => {
    const searchParams = new URLSearchParams({ email });
    const url = `setup/organization/${organizationID}/user/valid?${searchParams}`;
    try {
      await axiosPrivate.get(url);
      return true;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCheckUserPhone = createAsyncThunk(
  'organization/getCheckUserEmail',
  async (
    {
      phoneNumber,
      organizationID
    }: {
      phoneNumber: string;
      organizationID: string;
    },
    { rejectWithValue }
  ) => {
    const searchParams = new URLSearchParams({ phoneNumber });
    const url = `setup/organization/${organizationID}/user/valid?${searchParams.toString()}`;
    try {
      await axiosPrivate.get(url);
      return true;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postResendInvite = createAsyncThunk(
  'organization/postResendInvite',
  async (
    {
      email,
      organizationID
    }: {
      email: string;
      organizationID: string;
    },
    { rejectWithValue }
  ) => {
    const url = `setup/organization/${organizationID}/user/invite`;
    try {
      const response = await axiosPrivate.post(url, { email });
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteUser = createAsyncThunk(
  'organization/deleteUser',
  async (id: number, { rejectWithValue }) => {
    const url = `setup/organization/user/${id}`;
    try {
      const response = await axiosPrivate.delete(url);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type SiteDTO = {
  siteName: string;
  status: 'ACTIVE' | 'INACTIVE';
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zipCode: string;
};

type ProgramDTO = {
  programId?: number;
  name: string;
  sites: SiteDTO[];
  programHiring?: {
    campaigns: number[];
    chwExisting?: number;
    chwNew?: number;
    managerExisting?: number;
    managerNew?: number;
    directorExisting?: number;
    directorNew?: number;
    coordinatorExisting?: number;
    coordinatorNew?: number;
  };
};

export const putCreateProgream = createAsyncThunk(
  'organization/putCreateprogram',
  async (
    { organizationID, program }: { organizationID: string; program: ProgramDTO },
    { rejectWithValue }
  ) => {
    const url = `setup/organization/${organizationID}/program/build-a-program`;
    try {
      const response = await axiosPrivate.put(url, program);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export type DoingTheWorkDTO = {
  programId?: string;
  eligibilityCriteria: {
    geographyEnabled: boolean;
    zipCodes: number[];
    languageEnabled: boolean;
    languages: number[];
    insuranceEnabled: boolean;
    insurances: number[];
    healthConditionEnabled: boolean;
    healthConditions: number[];
    priorHospitalsVisitsEnabled: boolean;
    priorHospitalsVisitsCondition: string;
    priorHospitalsVisitsValue: number;
    socialNeedsEnabled: boolean;
    referralEnabled: boolean;
    pregnancyIncludeEnabled: boolean;
    rulesForEligibilityEnabled: boolean;
    rulesForEligibility: string;
  };
  enrollmentGoals: {
    chwNumber?: number;
    recommendedAverageAnnualEnrollment?: number;
  };
  maximumCaseload: {
    maximumChwCaseload: number;
  };
  autoConnectSettings: {
    autoConnectFrequencyMinutes: string;
  };
  programModalityAndDuration: {
    type: string;
    zipCodes: number[];
    duration: number | null;
    period: string | null;
  };
};

export const putUpdateDoingTheWork = createAsyncThunk(
  'organization/putUpdateDoingTheWork',
  async (
    { organizationID, doingTheWork }: { organizationID: string; doingTheWork: DoingTheWorkDTO },
    { rejectWithValue }
  ) => {
    const url = `setup/organization/${organizationID}/program/doing-the-work`;
    try {
      const response = await axiosPrivate.put(url, doingTheWork);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getProgram = createAsyncThunk(
  'organization/getProgram',
  async (
    {
      organizationID,
      programID,
      includePresets
    }: { organizationID: string; programID: string | number; includePresets: boolean },
    { rejectWithValue }
  ) => {
    const programUrl = `setup/organization/${organizationID}/program/${programID}`;
    const hiringUrl = `setup/organization/program/${programID}/hiring`;
    const eligibilityCriteriaUrl = `setup/organization/program/${programID}/eligibility/criteria`;
    const enrollmentGoalsUrl = `setup/organization/program/${programID}/goals`;
    const maximumCaseloadUrl = `setup/organization/program/${programID}/caseload`;
    const autoConnectSettingsUrl = `setup/organization/program/${programID}/auto-connect-settings`;
    const modalityAndDurationUrl = `setup/organization/program/${programID}/modality-and-duration`;
    const presetsUrl = `setup/organization/program/${programID}/presets`;
    const programPromise = axiosPrivate.get(programUrl);
    const hiringPromise = axiosPrivate.get(hiringUrl);
    const eligibilityCriteriaPromise = axiosPrivate.get(eligibilityCriteriaUrl);
    const enrollmentGoalsPromise = axiosPrivate.get(enrollmentGoalsUrl);
    const maximumCaseloadPromise = axiosPrivate.get(maximumCaseloadUrl);
    const autoConnectSettingsPromise = axiosPrivate.get(autoConnectSettingsUrl);
    const modalityAndDurationPromise = axiosPrivate.get(modalityAndDurationUrl);
    const presetsPromise = includePresets ? axiosPrivate.get(presetsUrl) : null;

    try {
      const [
        programResponse,
        hiringResponse,
        eligibilityCriteria,
        enrollmentGoals,
        maximumCaseload,
        autoConnectSettings,
        programModalityAndDuration,
        presets
      ] = await Promise.all([
        programPromise,
        hiringPromise,
        eligibilityCriteriaPromise,
        enrollmentGoalsPromise,
        maximumCaseloadPromise,
        autoConnectSettingsPromise,
        modalityAndDurationPromise,
        presetsPromise
      ]);
      return {
        ...programResponse.data,
        programHiring: hiringResponse.data,
        eligibilityCriteria: eligibilityCriteria.data,
        enrollmentGoals: enrollmentGoals.data,
        maximumCaseload: maximumCaseload.data,
        autoConnectSettings: autoConnectSettings.data,
        programModalityAndDuration: programModalityAndDuration.data,
        presets: presets?.data ?? null
      };
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getIsProgramActive = createAsyncThunk(
  'organization/getIsProgramActive',
  async (
    { organizationID, programID }: { organizationID: string; programID: string | number },
    { rejectWithValue }
  ) => {
    const programUrl = `setup/organization/${organizationID}/program/${programID}`;

    const programPromise = axiosPrivate.get(programUrl);
    try {
      const [programResponse] = await Promise.all([programPromise]);
      return programResponse.data.status;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCheckProgramName = createAsyncThunk(
  'organization/getCheckProgramName',
  async (
    {
      programName,
      organizationID
    }: {
      programName: string;
      organizationID: string;
    },
    { rejectWithValue }
  ) => {
    const url = `setup/organization/${organizationID}/program/check?programName=${programName}`;
    try {
      const response = await axiosPrivate.get(url);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getIntegrationsEMR = createAsyncThunk(
  'organization/getIntegrationsEMR',
  async (organizationID: string, { rejectWithValue }) => {
    const url = `setup/organization/${organizationID}/integration/emr`;
    try {
      const response = await axiosPrivate.get(url);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getIntegrationsEMRMessageTypes = createAsyncThunk(
  'organization/getIntegrationsEMRMessageTypes',
  async (_, { rejectWithValue }) => {
    const url = `setup/organization/integration/emr/message-types`;
    try {
      const response = await axiosPrivate.get(url);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteSite = createAsyncThunk(
  'organization/deleteSite',
  async ({ programID, siteId }: { programID: string; siteId: string }, { rejectWithValue }) => {
    const url = `setup/organization/program/${programID}/site`;
    try {
      const response = await axiosPrivate.delete(url, { data: [siteId] });
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postActivateProgram = createAsyncThunk(
  'organization/postActivateProgram',
  async (
    { programID, organizationID }: { programID: string; organizationID: string },
    { rejectWithValue }
  ) => {
    const url = `setup/organization/${organizationID}/program/activate/${programID}`;
    try {
      const response = await axiosPrivate.post(url);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putIntegrationsEMR = createAsyncThunk(
  'organization/putIntegrationsEMR',
  async (
    { organizationID, data }: { organizationID: string; data: IntegrationsPropsEMR },
    { rejectWithValue }
  ) => {
    const url = `setup/organization/${organizationID}/integration/emr`;
    try {
      const response = await axiosPrivate.put(url, data);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getСadence = createAsyncThunk(
  'organization/getСadence',
  async (organizationID: string, { rejectWithValue }) => {
    const url = `setup/organization/${organizationID}/cadence`;
    try {
      const response = await axiosPrivate.get(url);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCadenceUnits = createAsyncThunk(
  'organization/getCadenceUnits',
  async (_, { rejectWithValue }) => {
    const url = `setup/organization/cadence/units`;
    try {
      const response = await axiosPrivate.get(url);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putСadence = createAsyncThunk(
  'organization/putСadence',
  async (
    { organizationID, data }: { organizationID: string; data: CadenceProps },
    { rejectWithValue }
  ) => {
    const url = `setup/organization/${organizationID}/cadence`;
    try {
      const response = await axiosPrivate.put(url, data);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getValidPrograms = createAsyncThunk(
  'organization/getValidPrograms',
  async (organizationID: string, { rejectWithValue }) => {
    const url = `setup/organization/${organizationID}/program/valid`;
    try {
      const response = await axiosPrivate.get(url);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postActivateOrganization = createAsyncThunk(
  'organization/postActivateOrganization',
  async (
    { organizationID, data }: { organizationID: string; data: string[] },
    { rejectWithValue }
  ) => {
    const url = `setup/organization/${organizationID}/activate`;
    try {
      const response = await axiosPrivate.post(url, data);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUserListAutocomplete: any = createAsyncThunk(
  'organization/getUserListAutocomplete',
  async ({ id, maxResult, term }: AutocompleteDTO, { rejectWithValue }) => {
    let termData = term;
    if (term.includes(' ')) {
      termData = term.split(' ').reduce((previousValue, currentValue) => {
        if (currentValue === '') {
          return previousValue;
        }
        return `${previousValue}%20${currentValue}`;
      });
    }

    try {
      const response = await axiosPrivate.get(
        `setup/organization/${id}/user/autocomplete?maxResult=${maxResult}&term=${termData}`
      );
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const patchChangeOrganizationStatus = createAsyncThunk(
  'organization/patchChangeOrganizationStatus',
  async ({ data }: { data: { organizationID: number; status: string } }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.patch(`setup/organization/change-status`, {
        organizationId: data.organizationID,
        status: data.status,
        contractTerminated: true,
        dataExportOffered: true
      });
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const patchChangeProgramStatus = createAsyncThunk(
  'organization/patchChangeProgramStatus',
  async ({ data }: { data: { programID: number; status: string } }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.patch(`setup/organization/program/change-status`, {
        programId: data.programID,
        status: data.status
      });
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUsersImage = createAsyncThunk(
  'organization/getUsersImage',
  async (
    { profileImageKey, userId }: { profileImageKey: string; userId?: number },
    { rejectWithValue }
  ) => {
    const url = `setup/organization/user/profile-image?key=${profileImageKey}`;
    try {
      const response = await axiosPrivate.get(url, { responseType: 'blob' });
      return {
        data: response.data,
        userId: userId
      };
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOrganizationConnections: any = createAsyncThunk(
  'organization/getOrganizationConnections',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`setup/organization/auth0/connections`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putOrganizationSSO = createAsyncThunk(
  'organization/putOrganizationSSO',
  async (data: OrganizationSSO, { rejectWithValue }) => {
    const url = `setup/organization/auth0/${data.organizationId}`;
    try {
      const response = await axiosPrivate.put(url, data);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getProgramsReport = createAsyncThunk(
  'organization/getProgramsReport',
  async (
    { chwIds, organizationId }: { chwIds?: string[] | null; organizationId: string },
    { rejectWithValue }
  ) => {
    const chwIdsFiltered = chwIds && chwIds.length > 0 ? `?chwIds=${chwIds}` : `?chwIds=`;

    try {
      const response = await axiosPrivate.get(
        `setup/organization/${organizationId}/programs-for-report-selector-multiple${chwIdsFiltered}`
      );
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCHWReport = createAsyncThunk(
  'organization/getCHWReport',
  async (
    { programIds, organizationId }: { programIds?: string[] | null; organizationId: string },
    { rejectWithValue }
  ) => {
    const programIdsFiltered =
      programIds && programIds.length > 0 ? `?programIds=${programIds}` : `?programIds=`;

    try {
      const response = await axiosPrivate.get(
        `setup/organization/${organizationId}/chws-for-reports-multiple${programIdsFiltered}`
      );
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
