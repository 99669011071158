import { useEffect, useRef, useState } from 'react';
import NoSleep from 'nosleep.js';

export default function KeepAwake() {
  const wakeLockRef = useRef<any>(null);
  const [noSleep, setNoSleep] = useState<NoSleep | null>(null);

  useEffect(() => {
    const requestWakeLock = async () => {
      try {
        if ('wakeLock' in navigator) {
          wakeLockRef.current = await (navigator as any).wakeLock.request('screen');
          console.log('Screen wake lock is active.');
        } else {
          console.warn('Wake Lock API not supported, using NoSleep.js instead.');
          const ns = new NoSleep();
          ns.enable(); // Activate NoSleep.js fallback
          setNoSleep(ns);
        }
      } catch (err) {
        console.error('Failed to acquire wake lock:', err);
      }
    };

    requestWakeLock();

    // Reapply wake lock if the page visibility changes
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && wakeLockRef.current === null) {
        requestWakeLock();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);

      // Release Wake Lock API
      if (wakeLockRef.current) {
        wakeLockRef.current.release();
        wakeLockRef.current = null;
      }

      // Disable NoSleep.js fallback
      noSleep?.disable();
    };
  }, []);

  return <div></div>;
}
