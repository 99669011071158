import { Button } from 'components/button';
import { WifiMeter } from 'components/wifiMeter';
import KeepAwake from 'modules/keepAwake/keepAwake';
import React, { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import { axiosPublic } from 'modules/networkTools/networkTools';

import { notifyUserError, notifyUserSuccess } from '../../utils/notifications';
import style from './ocrAudio.module.scss';
import CustomLiveAudioVisualizer from './waveform';

interface FileUploadResultProps {
  res: any;
}

type OcrRecorderProps = {
  onAudioRecorded?: (audioBlob: Blob) => void;
};

interface UploadResponse {
  url: string;
  fileName: string;
}

const OcrRecorderAudio: React.FunctionComponent<OcrRecorderProps> = ({ onAudioRecorded }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false); // New state for pause/resume
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [audioURL, setAudioURL] = useState<string | null>(null);
  const [, setAudioName] = useState<string>('audio-conversation-' + dayjs().format('MMMDD-THH-mm'));
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [, setIsLoading] = useState(false);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/mp4' });
      mediaRecorderRef.current = mediaRecorder;

      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const recordedAudioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp4' });
        setAudioBlob(recordedAudioBlob);
        const audioUrl = URL.createObjectURL(recordedAudioBlob);
        setAudioURL(audioUrl);

        if (onAudioRecorded) {
          onAudioRecorded(recordedAudioBlob);
        }
      };

      mediaRecorder.start();
      setIsRecording(true);
      setIsPaused(false); // Reset pause state
    } catch (err) {
      console.error('Error accessing microphone: ', err);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsPaused(false); // Reset pause state
    }
  };

  const pauseRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
    }
  };

  const resumeRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'paused') {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
    }
  };

  const handleDeleteAudio = () => {
    setAudioBlob(null);
    setAudioURL(null);
    audioChunksRef.current = [];
    setAudioName('audio-conversation-' + dayjs().format('MMMDD-THH-mm'));
  };

  const uploadPressignedUrl = async (file: Blob | null) => {
    console.log('--file: ', file);
    if (!file) return;

    // 1st step: Get presigned URL
    try {
      const responseGetPresigned = await axiosPublic.get(`health-scribe/upload/url`);
      const { url, fileName }: UploadResponse = responseGetPresigned.data;
      console.log('fileName: ', fileName);
      console.log('url: ', url);

      const config = {
        headers: {
          'Content-Type': 'audio/mp4'
        }
      };

      // 2nd step: Upload file to S3 bucket using PUT request
      const responseUpload = await axiosPublic.put(url, file, config);
      console.log('Upload successful:', responseUpload.data);

      // 3rd step: Run transcribe
      const responseTranscribe = await axiosPublic.post(
        `health-scribe/transcribe?fileName=${fileName}`
      );
      notifyUserSuccess('Record uploaded successfully');
      console.log('Running transcribe successful:', responseTranscribe.data);
    } catch (error) {
      console.error('Error s3 presigned:', error);
    }
  };
  const uploadToS3BucketForOCRAudio = async (file: Blob): Promise<FileUploadResultProps> => {
    setIsLoading(true);
    try {
      await uploadPressignedUrl(audioBlob);

      handleDeleteAudio();
      return { res: true };
    } catch (error: any) {
      notifyUserError(`Error during upload: ${error?.message}`);
      return { res: null };
    } finally {
      setIsLoading(false); // Set loading to false when the upload completes
    }
  };

  const sendAudio = async (blob: Blob) => {
    await uploadToS3BucketForOCRAudio(blob);
  };

  const handleSendAudioClick = async () => {
    if (audioBlob) {
      await sendAudio(audioBlob);
    }
  };

  useEffect(() => {
    handleSendAudioClick();
  }, [audioBlob]);

  return (
    <div className={style.ocrAudio}>
      {mediaRecorderRef.current && isRecording && (
        <CustomLiveAudioVisualizer mediaRecorder={mediaRecorderRef.current} />
      )}
      <KeepAwake />
      {false && <WifiMeter />}
      {!audioURL && (
        <div>
          <Button
            text={isRecording ? 'Stop Recording' : 'Start Recording'}
            theme="primary"
            customStyleClass={style.recordButton}
            handleOnClick={isRecording ? stopRecording : startRecording}
          />
          {isRecording && (
            <Button
              text={isPaused ? 'Resume Recording' : 'Pause Recording'}
              theme="secondary"
              customStyleClass={style.pauseButton}
              handleOnClick={isPaused ? resumeRecording : pauseRecording}
            />
          )}
        </div>
      )}
      {audioURL && (
        <div className={style.audioPlayer}>
          <audio ref={audioRef} controls src={audioURL} preload="metadata" />
          {/* <Button
            text="Delete Audio"
            theme="danger"
            customStyleClass={style.deleteButton}
            handleOnClick={handleDeleteAudio}
          /> */}
          {/* <Button
            text="Upload Audio"
            theme="primary"
            customStyleClass={style.uploadButton}
            handleOnClick={() => uploadPressignedUrl(audioBlob)}
          /> */}
        </div>
      )}
    </div>
  );
};

export default OcrRecorderAudio;
