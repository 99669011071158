import { Stack } from 'components';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { Fragment } from 'react';
import { useAppSelector } from 'store/hooks';
import { Todo } from 'store/myTodos/myTodosSlice';
import { isOverdue } from 'store/myTodos/selectors';
import { useIsOnlyVisibleToUserWithPermissions } from 'utils/hooks/useIsOnlyVisibleTo';
import { PERMISSION } from 'utils/protectedRoutes/userRolesAndPermissionTypes';

import { TodoInitialValues } from '../todo';
import style from './info.module.scss';

type Props = {
  data: Todo;
};

const formatTime = (time: string) => {
  const dt = dayjs(`2024-01-01 ${time}`);
  return dt.minute() === 0 ? dt.format('h A') : dt.format('h:mm A');
};

const Info = ({ data }: Props) => {
  const { values } = useFormikContext<TodoInitialValues>();
  const view = useAppSelector((state) => state.myTodos.view);
  const searchVisible = useAppSelector((state) => state.myTodos.search.show);
  const canSeeClientTodos = useIsOnlyVisibleToUserWithPermissions([PERMISSION.VIEW_CLIENT_TODOS]);

  const clientName = values.client?.name ? values.client.name : null;
  const date = values.date ? dayjs(values.date).format('MMM D') : null;

  const startTime = values.startTime ? formatTime(values.startTime) : null;
  const endTime = values.endTime ? formatTime(values.endTime) : null;
  const location = values.location ? values.location : null;

  const info = [
    { text: clientName, show: canSeeClientTodos && (view === 'dayView' || searchVisible) },
    { text: date, show: view === 'clientView' || isOverdue(data) || searchVisible },
    { text: startTime && endTime ? `${startTime} - ${endTime}` : null, show: true },
    { text: location, show: true }
  ].filter((item) => item.text !== null && item.show === true);

  return (
    <Stack
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap="6px"
      ml="2px"
      overflow="hidden"
    >
      <p
        className={style.info}
        data-overdue={isOverdue(data)}
        title={info.map((item, index) => (index !== 0 ? ` | ${item.text}` : item.text)).join('')}
        data-status={data.status}
      >
        {info.map((item, index) => (
          <Fragment key={item.text}>
            {index !== 0 && <span className={style.separator}> | </span>}
            {item.text}
          </Fragment>
        ))}
      </p>
    </Stack>
  );
};

export default Info;
